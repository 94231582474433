import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { useQueryParams, StringParam } from "use-query-params";
import Masonry from "react-masonry-css";

// Preview
import { withPreview } from "gatsby-source-prismic";

// Context
import { PageType } from "../components/context/page-type";

// Context
import { useColorContext } from "../components/context/color-context";

const Page = styled.div`
  margin: 40px 0 0 0;

  & .events-container {
    padding: 30px;
    margin-bottom: 80px;

    background-color: ${props => props.highlightColor};

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }

    & .events-title {
      text-align: center;
      text-decoration: underline;
      text-underline-offset: 5.5px;

      margin: 0 0 1em 0;
    }

    & .inner-events-container {
    }

    & .single-event-container {
      margin: 0 0 2em 0;

      &:last-of-type {
        margin: 0;
      }

      & .additional-text {
        & p {
          font-size: 16px;
          line-height: 23px;
        }
      }

      & .event-link {
        text-decoration: none;
      }
    }
  }

  & .my-masonry-grid {
    display: flex;
    margin-left: -40px; /* gutter size offset */
    width: auto;
  }
  & .my-masonry-grid_column {
    padding-left: 40px; /* gutter size */
    background-clip: padding-box;
  }

  & .support {
    grid-column-gap: 0;
    grid-row-gap: 0;

    & .section-title,
    & .support-logos {
      grid-column: 1 / 7;
    }

    & .support-logos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      & .single-image-container {
        margin: 0 40px 0 0;

        &:last-of-type {
          margin: 0;
        }

        & img {
          width: auto;
          height: 60px;
        }
      }

      @media (max-width: 768px) {
        display: block;
        flex-direction: unset;
        flex-wrap: unset;
        align-items: unset;

        & .single-image-container {
          margin: 30px 0;
        }
      }
    }
  }
`;

const Article = styled.article`
  margin-bottom: 80px;

  & .title {
    margin: 19px 0 0 0;
  }

  @media (max-width: 1000px) {
  }
  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }
`;

const TagsContainer = styled.div`
  margin: 14px 0 0 0;

  & ol {
    margin: 0 0 10px 0;

    & li {
      padding: 5px 10px;
      margin: 0 10px 0 0;

      font-size: 16px;
      line-height: 23px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
      }

      background: #ffffff;
      border-radius: 3px;

      &.active {
        background-color: #000;

        & button {
          color: #fff;
        }
      }

      &:hover {
        background-color: #000;

        & button {
          color: #fff;
        }
      }
    }
  }

  & .event-link {
    & a {
      display: block;

      padding: 5px 10px;
      margin: 0 10px 0 0;

      font-size: 16px;
      line-height: 23px;

      text-decoration: none;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0;
      }

      background: #ffffff;
      border-radius: 3px;

      color: #000;
      text-decoration: none;

      &:hover {
        background-color: #000;

        color: #fff;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 80px;

  @media (max-width: 768px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }
`;

const Index = ({ data }) => {
  const [pageType, setPageType] = useContext(PageType);
  const { randomColors } = useColorContext();

  // reads query parameters `foo` and `bar` from the URL and stores their decoded values
  const [query, setQuery] = useQueryParams({
    what: StringParam,
    where: StringParam,
  });

  const breakpointColumnsObj = {
    default: 3,
    1000: 2,
    700: 1,
  };

  useEffect(() => {
    setPageType(`home`);
  }, [data]);

  const events = data.prismicProgramme.data.events
    .filter(event => event.event.document !== null)
    .filter(event => {
      if (query.where !== undefined) {
        if (
          event.event.document.data.places
            .filter(place => place !== "")
            .filter(place => place.place !== null)
            .map(place => place.place.toLowerCase())
            .includes(query.where)
        ) {
          return event;
        }
      } else {
        return event;
      }
    })
    .filter(event => {
      if (query.what !== undefined) {
        if (
          event.event.document.data.types
            .filter(type => type !== "")
            .filter(type => type.type !== null)
            .map(type => type.type.toLowerCase())
            .includes(query.what)
        ) {
          return event;
        }
      } else {
        return event;
      }
    })
    .map((event, index) => {
      return (
        <div
          key={`single_homepage_event_${event.event.document.id}_${index}`}
          className="single-event-container"
        >
          <Link
            to={event.event.document.url}
            className="event-link"
            aria-label={event.event.document.data.title.text}
          >
            <div
              className="title uppercase"
              dangerouslySetInnerHTML={{
                __html: event.event.document.data.title.html,
              }}
            />

            {event.event.document.data.subtitle.text !== "" && (
              <div
                className="subtitle"
                dangerouslySetInnerHTML={{
                  __html: event.event.document.data.subtitle.html,
                }}
              />
            )}

            <div
              className="additional-text"
              dangerouslySetInnerHTML={{
                __html: event.event.document.data.additional_text.html,
              }}
            />
          </Link>

          {event.event.document.data.event_link.url !== "" && (
            <TagsContainer className="flex row flex-wrap">
              <li className="event-link">
                <a
                  href={event.event.document.data.event_link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {event.event.document.data.event_link_text}
                </a>
              </li>
            </TagsContainer>
          )}
        </div>
      );
    });

  const content = data.prismicProgramme.data.projects
    .filter(project => project.project.document !== null)
    .filter(project => {
      if (query.where !== undefined) {
        if (
          project.project.document.data.places
            .filter(place => place !== "")
            .filter(place => place.place !== null)
            .map(place => place.place.toLowerCase())
            .includes(query.where)
        ) {
          return project;
        }
      } else {
        return project;
      }
    })
    .filter(project => {
      if (query.what !== undefined) {
        if (
          project.project.document.data.types
            .filter(type => type !== "")
            .filter(type => type.type !== null)
            .map(type => type.type.toLowerCase())
            .includes(query.what)
        ) {
          return project;
        }
      } else {
        return project;
      }
    })
    .map((project, index) => {
      const where = project.project.document.data.places
        .filter(place => place !== "")
        .filter(place => place.place !== null)
        .map((place, index) => (
          <li
            key={`single_project_place_${index}_${project.project.document.id}`}
            className={
              query.where !== undefined &&
              query.where === place.place.toLowerCase()
                ? `active`
                : ``
            }
          >
            <button
              onClick={() => setQuery({ where: place.place.toLowerCase() })}
            >
              {place.place}
            </button>
          </li>
        ));

      const what = project.project.document.data.types
        .filter(type => type !== "")
        .filter(type => type.type !== null)
        .map((type, index) => (
          <li
            key={`single_project_type_${index}_${project.project.document.id}`}
            className={
              query.what !== undefined && query.what === type.type.toLowerCase()
                ? `active`
                : ``
            }
          >
            <button onClick={() => setQuery({ what: type.type.toLowerCase() })}>
              {type.type}
            </button>
          </li>
        ));

      return (
        <Article
          key={`single_homepage_project_${project.project.document.id}_${index}`}
          // className="my-masonry-grid_column"
        >
          <Link
            to={project.project.document.url}
            aria-label={project.project.document.data.title.text}
          >
            {project.project.document.data.image.fluid !== null && (
              <img
                srcSet={project.project.document.data.image.fluid.srcSetWebp}
                src={project.project.document.data.image.fluid.srcWebp}
                alt={project.project.document.data.image.alt}
                loading={`lazy`}
              />
            )}
          </Link>
          <div
            className="title uppercase"
            dangerouslySetInnerHTML={{
              __html: project.project.document.data.title.html,
            }}
          />

          {project.project.document.data.subtitle.text !== "" && (
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: project.project.document.data.subtitle.html,
              }}
            />
          )}

          <TagsContainer className="flex row flex-wrap">
            {where.length >= 1 && (
              <ol className="flex row flex-wrap">{where}</ol>
            )}
            {what.length >= 1 && <ol className="flex row flex-wrap">{what}</ol>}
          </TagsContainer>
        </Article>
      );
    });

  const funders = data.prismicProgramme.data.funders.map((funder, index) => (
    <div
      key={`single_image_funder_${index}`}
      className="single-image-container"
    >
      <div className="image-container">
        {funder.funder.fluid !== null && funder.funder.fluid !== undefined && (
          <img
            srcSet={funder.funder.fluid.srcSetWebp}
            src={funder.funder.fluid.srcWebp}
            alt={funder.funder.alt}
            loading={`lazy`}
          />
        )}
      </div>
    </div>
  ));

  return (
    <Page highlightColor={randomColors[1]}>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {events.length >= 1 && (
          <div className="events-container">
            <h2 className="events-title">Events</h2>

            <div className="inner-events-container">{events}</div>
          </div>
        )}

        {content}
      </Masonry>

      <Grid className="support">
        <div className="section-title">
          <div
            className="subtitle"
            dangerouslySetInnerHTML={{
              __html: data.prismicProgramme.data.funders_text.html,
            }}
          />
        </div>

        <div className="support-logos">{funders}</div>
      </Grid>
    </Page>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicProgramme {
      _previewable
      data {
        funders_text {
          html
        }
        funders {
          funder {
            alt
            fluid {
              srcWebp
              srcSetWebp
            }
          }
        }
        events {
          event {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    html
                    text
                  }
                  subtitle {
                    html
                    text
                  }
                  additional_text {
                    html
                  }
                  places {
                    place
                  }
                  types {
                    type
                  }
                  event_link {
                    url
                  }
                  event_link_text
                }
              }
            }
          }
        }
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    html
                    text
                  }
                  subtitle {
                    html
                    text
                  }
                  image {
                    alt
                    fluid {
                      srcWebp
                      srcSetWebp
                    }
                  }
                  places {
                    place
                  }
                  types {
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
